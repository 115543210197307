import { gql } from '@apollo/client';
import { getPalmSubgraph } from './getSubgraph';
import { BigNumber } from 'ethers';
import { getUnixTime, subHours } from 'date-fns';
import useSWR from 'swr';

interface VolumeStatsData {
  id: string;
  margin: string;
  liquidation: string;
}

interface HourlyVolumeData {
  id: string;
  tokenB: string;
  margin: string;
  liquidation: string;
}

function getLast24RangeTimestamps(hours: number) {
  const now = new Date();
  const startTimestamp = getUnixTime(subHours(now, hours));
  const endTimestamp = getUnixTime(now);

  return {
    startTimestamp,
    endTimestamp,
  };
}

type HourlyVolume = {
  volume: BigNumber
}
export function useHourlyVolume(hours = 24) {
  const client = getPalmSubgraph();

  async function fetcher(): Promise<HourlyVolume> {
    const { startTimestamp, endTimestamp } = getLast24RangeTimestamps(hours);

    const query = gql`
      {
        hourlyVolumeByTokens(where:{
          timestamp_gte: ${startTimestamp},
          timestamp_lte: ${endTimestamp},
        }) {
          id
          tokenB
          margin
          liquidation
        }
      }
    `;

    const res = await client.query<{ hourlyVolumeByTokens: HourlyVolumeData[] }>({
      query,
      fetchPolicy: 'network-only',
    });

    if (res.errors) {
      console.error('GraphQL Errors:', res.errors);
    }

    const data = res.data.hourlyVolumeByTokens;
    // Calculate the volume from the retrieved data
    const volume = data.reduce((acc, item) => {
      return acc.add(item.margin).add(item.liquidation);
    }, BigNumber.from(0));

    return {
      volume,
    };
  }

  const swr = useSWR<HourlyVolume>(['Exchange:24h-volume', hours], fetcher, {
    refreshInterval: 15000,
    fallbackData: { volume: BigNumber.from(0) }
  });

  return swr;
}

type TotalVolume = {
  volume: BigNumber
}
export function useTotalVolume() {
  const client = getPalmSubgraph();
  async function fetcher() {

    const query = gql`
      {
        volumeStats(where:{
          period: total
        }) {
          id
          margin
          liquidation
        }
      }
    `;

    const res = await client.query<{ volumeStats: VolumeStatsData[] }>({
      query,
      fetchPolicy: 'network-only',
    });

    if (res.errors) {
      console.error('GraphQL Errors:', res.errors);
    }

    const data = res.data.volumeStats;
    // Calculate the volume from the retrieved data
    const volume = data.reduce((acc, item) => {
      return acc.add(item.margin).add(item.liquidation);
    }, BigNumber.from(0));

    return {
      volume,
    };
  }

  const swr = useSWR<TotalVolume>(['Exchange:24h-volume', 'total'], fetcher, {
    refreshInterval: 15000,
    fallbackData: { volume: BigNumber.from(0) }
  });

  return swr;
}

type HourlyVolumeByToken = {
  volume: BigNumber
}
export function useHourlyVolumeByToken(indexToken: string, hours: number | undefined = 24) {
  type HourlyVolumeByTokenQueryData = {
    id: string;
    tokenA: string;
    tokenB: string;
    timestamp: number;
    swap: string;
    margin: string;
    liquidation: string;
    mint: string;
    burn: string;
  };

  async function fetcher(): Promise<HourlyVolumeByToken> {
    const client = getPalmSubgraph();
    const { startTimestamp, endTimestamp } = getLast24RangeTimestamps(hours)

    const query = gql`
      {
        hourlyVolumeByTokens(where:{
          timestamp_gte: ${startTimestamp},
          timestamp_lte: ${endTimestamp},
        }) {
          id
          tokenB
          margin
          liquidation
        }
      }
    `;

    const res = await client.query<{ hourlyVolumeByTokens: HourlyVolumeByTokenQueryData[] }>({
        query,
        fetchPolicy: "network-only"
    })

    const volumesBytToken =  res.data.hourlyVolumeByTokens.filter(({ tokenB }) => {
      return tokenB.toLowerCase() === indexToken.toLowerCase()
    })

    const volume = volumesBytToken.reduce((acc, item) => {
      return acc.add(item.margin).add(item.liquidation);
    }, BigNumber.from(0));

    return {
      volume,
    };
  }

  const swr = useSWR<Awaited<ReturnType<typeof fetcher>> | undefined>(
    ["Exchange:24h-volume", indexToken, hours], fetcher,
    {
      refreshInterval: 15000,
      fallbackData: { volume: BigNumber.from(0) }
    }
  );

  return swr;
}

export function useFeesAndUsers() {
  const client = getPalmSubgraph();

  async function fetcher() {
    const query = gql`
      {
        userStat(id: "total") {
          id
          uniqueCountCumulative
          actionCount
        }
        feeStat(id: "total") {
          id
          period
          mint
          margin
        }
      }
    `;

    const res = await client.query<{
      userStat: {
        id: string;
        uniqueCountCumulative: string;
        actionCount: string;
      };
      feeStat: {
        id: string;
        period: string;
        mint: string;
        burn: string;
        margin: string;
      };
    }>({
      query,
      fetchPolicy: 'network-only',
    });

    if (res.errors) {
      console.error('GraphQL Errors:', res.errors);
      return {
        error: res.errors,
      };
    }

    const userData = res.data.userStat;
    const feeData = res.data.feeStat;

    const v1UsersCount = 2210

    return {
      users: {
        uniqueCountCumulative: String(v1UsersCount + Number(userData.uniqueCountCumulative)),
        actionCount: userData.actionCount,
      },
      fees: {
        mint: feeData.mint,
        margin: feeData.margin,
      },
    };
  }

  const swr = useSWR(['Exchange:fees-and-users'], fetcher, {
    refreshInterval: 15000,
  });

  return swr;
}

type TradeStatisticsT = {
  volume: number;
  foxifyFees: number;
  pnl: number;
  bestTrade: number;
  worstTrade: number;
};
type DashboardStatisticsT = {
  daily: TradeStatisticsT;
  weekly: TradeStatisticsT;
  allTime: TradeStatisticsT;
};
export function useKitsuneStats() {
  const isLocalStorageAvailable = (): boolean => {
    try {
      if (typeof window === "undefined") return false; // No window in SSR or certain environments
      if (!window.localStorage) return false; // localStorage might not be supported
      // Test writing to localStorage (some browsers in private mode may throw)
      const testKey = "__testLocalStorage__";
      window.localStorage.setItem(testKey, "test");
      window.localStorage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  };

  async function fetcher() {
    if (isLocalStorageAvailable()) {
      const localData = localStorage.getItem("statisticsData");
      if (localData) {
        try {
          // Parse and set immediately
          const parsedData = JSON.parse(localData) as DashboardStatisticsT;
          return parsedData;
        } catch (error) {
          // console.error("Could not parse local statisticsData", error);
        }
      }
    }
    try {
      const response = await fetch("https://kitsune.foxify.trade/api/statistics/dashboard", {
        mode: "cors",
      });
      if (!response.ok) throw new Error("Failed to fetch statistics data");
      const data = (await response.json()) as DashboardStatisticsT;
  
      if (isLocalStorageAvailable()) {
        localStorage.setItem("statisticsData", JSON.stringify(data));
      }
      return data;
    } catch (error) {
      // console.log(error);
    }
  }

  const swr = useSWR<DashboardStatisticsT>('KitsuneStats', fetcher, {
    refreshInterval: 15000,
    fallbackData: {
      daily: { volume: 0, foxifyFees: 0, pnl: 0, bestTrade: 0, worstTrade: 0 },
      weekly: { volume: 0, foxifyFees: 0, pnl: 0, bestTrade: 0, worstTrade: 0 },
      allTime: { volume: 0, foxifyFees: 0, pnl: 0, bestTrade: 0, worstTrade: 0 },
    },
  });
  return swr;

}

